@font-face {
    font-family: 'geometos_neue_extralight';
    src: url(../fonts/geometos_neue_extralight.otf);
}

@font-face {
    font-family: 'geometos_neue_black';
    src: url(../fonts/geometos_neue_black.otf);
}

@font-face {
    font-family: 'Gunterz-bold';
    src: url(../fonts/Gunterz-bold.otf);
}
$color-main: #f5f5f5;
$color-secondary: #df1e2a;
// 
body {
    font-family: 'geometos_neue';
    width: 100%;
    height: 100vh;
    background-color: $color-main;
    margin: 0;
}
header {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: $color-secondary;
    transition: .75s ease-in all;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-bottom: 2px solid #fff;
    &.loaded {
        height: 6.1vh;
        .logo-body {
            transform: scale(0.33);
            margin-top: 6vh;
        }
    }
}
.logo-body {
    width: 240px;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: .75s ease-in all;
    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        font-size: 22px;
        color: $color-main;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
        background: $color-secondary;
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
        span:nth-child(1){
            font-family: 'Gunterz-bold';
            margin-top: 4px;
            font-weight: 1000;
        }
        span:nth-child(2){
            font-family: 'geometos_neue_extralight';
        }
    }
    
    .logo-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%,-50%);
        circle {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            stroke: $color-main;
            animation: stroke 2s ease-out forwards;
        }
    }
}


@keyframes stroke {
    to {
    stroke-dashoffset: 0;
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.dd-list {
    flex-direction: column;
    position: absolute;
    right: 24px;
    opacity: 0;
    transition: .3s ease-in-out all;
    transition-delay: .75s;
    .loaded & {
        opacity: 1;
        margin-top: 6vh;
    }
    a[role="button"]{
        cursor: pointer;
    }
}
.dropdown-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    height: 0%;
    overflow: hidden;
    transition: .3s ease-in-out all;
    top: 110%;
}

.opened .dropdown-list {
    height: 280px;
}
.flag-container {
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    border: 2px solid #fff;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.flag {
    height: 80px;
    position: absolute;
    &.tur-flag {
        top: -60%;
        width: 60px;
        left: -13%;
    }
    &.gbr-flag {
        left: -47%;
        top: -60%;
        width: 70px;
    }
    &.fra-flag {
        top: -50%;
        left: -18%;
        width: 48px;
    }
    &.sau-flag {
        top: -54%;
        left: -36%;
        width: 62px;
    }
    &.ita-flag {
        top: -63%;
        left: -19%;
        width: 50px;
        background: #fff;
    }
    &.isr-flag {
        top: -6%;
        left: -25%;
        width: auto;
        height: 40px;
    }
    &.rus-flag {
        top: -63%;
        left: -19%;
        width: 60px;
    }
}
main {
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    width: 90%;
    padding-top: 4vh;
}
.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    & img {
        width: 100%;
        height: auto;
    }
    & .description {
        margin-top: 4vh;
        font-size: 20px;
        &.align-right {
            text-align: right;
        }
    }
}